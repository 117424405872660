<mat-nav-list>
    <mat-list-item>
        <mat-icon svgIcon="admin"></mat-icon>Administración
    </mat-list-item>
    <mat-list-item>
        <a routerLink="/admin/menu-diario" routerLinkActive="active" (click)="openLink($event)">
            <mat-icon svgIcon="menu"></mat-icon>Menú diario
        </a>
    </mat-list-item>
    <mat-list-item>
        <a routerLink="/admin/cartas" routerLinkActive="active" (click)="openLink($event)">
            <mat-icon svgIcon="cartas"></mat-icon>Cartas
        </a>
    </mat-list-item>
    <mat-list-item>
        <a routerLink="/admin/ajustes" routerLinkActive="active" (click)="openLink($event)">
            <mat-icon svgIcon="settings"></mat-icon>Ajustes
        </a>
    </mat-list-item>
</mat-nav-list>