import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export class TextDialogData {
  constructor(public title: string, public text: string) { }
}

@Component({
  selector: 'dsm-get-text-dialog',
  standalone: true,
  imports: [MatFormFieldModule, MatDialogModule, FormsModule, MatInputModule, MatButtonModule],
  templateUrl: './get-text.dialog.html',
  styleUrls: ['./get-text.dialog.scss']
})
export class GetTextDialog {
  constructor(
    private dialogRef: MatDialogRef<GetTextDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TextDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
