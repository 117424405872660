import { Component, OnInit } from '@angular/core';
import { latLng, tileLayer, marker, icon, LatLngExpression, Marker } from 'leaflet';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { ConfigService } from '../../service/firebase/config.service';

@Component({
    selector: 'dsm-map-page',
    standalone: true,
    imports: [LeafletModule],
    templateUrl: './map.page.html',
    styleUrls: ['./map.page.scss']
})
export class MapPage implements OnInit {

    private streetMaps = tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    private location!: Marker<any>;

    position!: LatLngExpression;
    layersControl!: any;
    options: any;

    constructor(private config: ConfigService) {
    }

    ngOnInit(): void {
        this.config.get().subscribe(config => {
            if (config) {
                this.position = [config.location.latitude, config.location.longitude];
                this.location = marker(this.position, {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'leaflet/marker-icon.png',
                        iconRetinaUrl: 'leaflet/marker-icon-2x.png',
                        shadowUrl: 'leaflet/marker-shadow.png'
                    })
                });
                this.layersControl = {
                    baseLayers: {
                        'Street Maps': this.streetMaps,
                    },
                    overlays: {
                        'Dehesa de Partenón': this.location,
                    }
                };
                this.options = {
                    layers: [
                        this.streetMaps, this.location
                    ],
                    zoom: 18,
                    center: latLng(this.position)
                };
            }
        });
    }
}
