export class DailyMenu {
    constructor(
        public id: string,
        public date: Date,
        public firsts: string[],
        public seconds: string[],
        public desserts: string[],
        public price: number,
        public obs: string
    ) { }
}