import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { Allergen } from '../../models/Allergen';
import { Product } from '../../models/Product';
import { AllergensService } from '../../service/firebase/allergens.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'dsm-admin-product',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MatInputModule, ReactiveFormsModule],
    templateUrl: './admin-product.dialog.html',
    styleUrls: ['./admin-product.dialog.scss']
})
export class AdminProductDialog implements OnInit {

    allergens!: Allergen[];
    form!: UntypedFormGroup;
    isNew: boolean = false
    allowOrder: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public product: Product,
        private dialogRef: MatDialogRef<Product>,
        private formBuilder: UntypedFormBuilder,
        private allergensService: AllergensService) { }

    ngOnInit(): void {
        this.allergensService.getAll().subscribe(allergens => this.allergens = allergens);
        if (!this.product) {
            this.product = { description: '', allergens: [], allowOrder: false, price: 0, id: '' };
            this.isNew = true;
        }
        this.createFomrGroup();
    }

    get f() {
        return this.form.controls;
    }

    get allowSave(): boolean {
        return this.form.valid;
    }

    onSave() {
        this.dialogRef.close(this.form.value);
    }

    onCancel() {
        this.dialogRef.close();
    }

    private createFomrGroup() {
        this.form = this.formBuilder.group({
            id: [this.product.id],
            description: [this.product.description, Validators.required],
            obs: [this.product.obs],
            allergens: [this.product.allergens],
            price: [this.product.price, [Validators.required, Validators.min(1)]],
            allowOrder: [this.allowOrder]
        });
    }
}
