import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

import { Product } from '../../models/Product';
import { Config } from '../../service/firebase/config.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'dsm-local-info',
  standalone: true,
  imports: [MatListModule, MatIconModule, MatButtonModule, MatDialogModule],
  templateUrl: './local-info.dialog.html',
  styleUrls: ['./local-info.dialog.scss']
})
export class LocalInfoDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: Config,
    private dialogRef: MatDialogRef<Product>) { }

  onClose() {
    this.dialogRef.close();
  }
}
