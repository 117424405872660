<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    <mat-form-field class="full-row">
        <mat-label>{{data.title}}</mat-label>
        <input matInput [(ngModel)]="data.text">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Descartar</button>
    <button mat-button [mat-dialog-close]="data" color="primary"
        [disabled]="'' === data.text.trim()">Guardar</button>
</mat-dialog-actions>