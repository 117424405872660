import { Injectable } from '@angular/core';
import { BaseFirebaseService } from './base.service';
import { Observable } from 'rxjs';
import { GeoPoint } from 'firebase/firestore';

export interface ConfigDailyMenu {
    enabled: boolean;
    weekDaysEnabled: string[];
    from: string;
    to: string;
    starters: string;
    maindishes: string;
    desserts: string;
    maxFirstPlates: number;
    maxSecondPlates: number;
    maxDessertPlates: number;
    noMenuMessage: string;
    noAvailableMessage: string;
}

export interface Config {
    id: string;
    name: string;
    logo: string;
    address: string;
    city: string;
    postalCode: number;
    province: string;
    phone?: string;
    email?: string;
    location: GeoPoint;
    taxesIncluded: boolean;
    menu: ConfigDailyMenu;
}

@Injectable({ providedIn: 'root' })
export class ConfigService extends BaseFirebaseService<Config> {
    private localId = 'la-dehesa-de-partenon';
    private collection: string = 'config';

    get(): Observable<Config | undefined> {
        return this.firestore.collection(this.collection).doc<Config>(this.localId).valueChanges();
    }

    save(config: Config) {
        this.firestore.collection<Config>(this.collection).doc(config.id).set(config);
    }
}
