@if(menu && config) {
<div class="menu page-margin-1">
    <p class="date">{{today}}</p>
    <div class="menu-title">
        <span>menú</span>
        <span>diario</span>
    </div>
    <p class="disponibilidad">Disponible desde las {{config.menu.from}} hasta {{config.menu.to}}</p>

    @if(menuAvailable && !menuUpdated) {
    <div class="no-menu">
        <div class="no-menu-desc">
            <p>{{config.menu.noMenuMessage}}</p>
        </div>
    </div>
    }
    @if(!menuAvailable) {
    <div class="no-menu">
        <div class="no-menu-desc">
            <p>{{config.menu.noAvailableMessage}}</p>
        </div>
    </div>
    }
    @if(menuAvailable && menuUpdated) {
    <p class="menu-section">{{config.menu.starters}}</p>
    @for(plate of menu.firsts; track plate) { <p class="dish">{{plate}}</p> }

    <p class="menu-section">{{config.menu.maindishes}}</p>
    @for(plate of menu.seconds; track plate) { <p class="dish">{{plate}}</p> }

    <p class="menu-section">{{config.menu.desserts}}</p>
    @for(plate of menu.desserts; track plate) { <p class="dish">{{plate}}</p> }

    <p class="menu-section"></p>
    <p class="obs">{{menu.obs}}</p>
    <p class="price">Precio (impuestos @if(!config.taxesIncluded) { no }incluidos)</p>
    <p class="price-value">{{menu.price | number: '1.2-2':'es'}} €</p>
    }
</div>
}