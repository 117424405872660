import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, Subject } from 'rxjs';
import { takeUntil, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BaseFirebaseService<T> {
    protected firestore: AngularFirestore = inject(AngularFirestore);
    protected cache$: Observable<T> | undefined;
    protected reload$ = new Subject<void>();

    reload() {
        this.reload$.next();
        this.cache$ = undefined;
    }

    protected getFromCollection<T>(collection: string): Observable<T[]> {
        return this.firestore.collection<T>(collection).valueChanges({ idField: 'uid' }).pipe(
            takeUntil(this.reload$),
            shareReplay(1)
        );
    }
}
