@if(!!carta) {
<h1 class="title">{{ carta.title }}</h1>
<section>
    @if(carta.description && '' !== carta.description) {
    <p class="carta-desc">{{ carta!.description }}</p>
    }
    @for(section of carta.sections; track section.id) {
    <div class="section">
        <div class="section-title" (click)="toggleSection(section)">
            <span>{{ section.title }}@if(isHandset){<span> ({{section.products.length}})</span>}</span>
            @if(isHandset){<mat-icon [svgIcon]="getIconSection(section)"></mat-icon>}
        </div>
        <div class="prods" [class.prods-visible]="isSectionExpanded(section)">
            @for(product of section.products; track i; let i = $index) {
            <div class="prod" (click)="showProdDetail(product)">
                <div class="prod-desc">
                    <span>{{ product.description }}</span>
                    <span class="prod-price">{{ product.price | number: '1.2-2':'es' }} €</span>
                </div>

                @if(product.obs && '' !== product.obs) {
                <p class="prod-obs">{{ product.obs }}</p>
                }
                <div class="prod-bottom">
                    @if(product.allergens && product.allergens.length > 0) {
                    <span class="allergens"><mat-icon svgIcon="info"></mat-icon> Contiene alérgenos</span>
                    }
                </div>
            </div>
            }
        </div>
    </div>
    }
    @if(carta.obs && '' !== carta.obs) {
        <p class="obs">{{carta!.obs}}</p>
    }
</section>
}
