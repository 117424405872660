import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import moment from 'moment';

import { BaseFirebaseService } from './base.service';
import { Carta } from '../../models/Carta';


@Injectable({
    providedIn: 'root'
})
export class CartasService extends BaseFirebaseService<Carta> {
    private weekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    private collection: string = 'cartas';

    getAll(): Observable<Carta[]> {
        return this.getFromCollection<Carta>(this.collection);
    }

    getActives(): Observable<Carta[]> {
        const weekDay = this.weekDays[moment().weekday()];

        return this.getAll().pipe(
            map(cartas => cartas.filter(c => c.isEnabled && c.weekDaysEnabled.find(wd => wd === weekDay)).sort((a, b) => a.order > b.order ? 1 : -1))
        )
    }

    getById(id: string | null): Observable<Carta> {
        return this.getAll().pipe(
            map(s => s.filter(is => is.id === id)[0])
        );
    }

    getNewId(): string {
        return this.firestore.createId();
    }

    save(carta: Carta) {
        if (!carta.id) {
            carta.id = this.firestore.createId();
        }
        this.firestore.collection<Carta>(this.collection).doc(carta.id).set(carta);
    }

    delete(carta: Carta) {
        this.firestore.collection<Carta>(this.collection).doc(carta.id).delete();
    }
}