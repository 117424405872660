<nav class="main-toolbar">
    <button type="button" aria-label="Vovler" mat-icon-button>
        <mat-icon></mat-icon>
    </button>
    <span class="title">{{title}}</span>
    <span class="flex-spacer"></span>
    @if(user) {
        <button mat-icon-button type="button" aria-label="Profile Info" [matMenuTriggerFor]="profileMenu">
            <img class="round-img-32" src="{{user.photoURL}}" aria-label="Imagen del usuario" />
        </button>
    } @else {
        @if(!(isHandset$ | async)) {
        <div class="signinup">
            <button mat-button (click)="onLogin()">Iniciar sesión</button>
        </div>
        } @else {
        <div>
            <button mat-button (click)="onLogin()">
                <mat-icon svgIcon="account_circle"></mat-icon>
            </button>
        </div>
        }
    }
</nav>
<mat-menu #profileMenu="matMenu">
    @if(user) {
        <div class="profile">
            <div class="profile-img">
                <img class="round-img-96" src="{{user.photoURL}}" aria-label="Imagen del usuario" />
                <div class="camera-icon">
                    <a routerLink="profile/img">
                        <mat-icon svgIcon="camera"></mat-icon>
                    </a>
                </div>
            </div>
            <div class="profile-info">
                <p>{{user.displayName}}</p>
            </div>
        </div>
        <button mat-menu-item (click)="onLogout()">
            <mat-icon svgIcon="exit"></mat-icon>
            <span>Cerrar sessión</span>
        </button>
    }
</mat-menu>