<h2 mat-dialog-title>{{config.name}}</h2>
<mat-dialog-content>
    <mat-list>
        <mat-list-item>
            <span matListItemTitle>{{config.address}}</span>
            <span matListItemTitle>{{config.postalCode}} - {{config.city}}</span>
            <span matListItemTitle>{{config.province}}</span>
        </mat-list-item>
        @if(config.phone) {
        <mat-list-item>
            <mat-icon svgIcon="phone"></mat-icon>{{config.phone}}
        </mat-list-item>
        }
        @if(config.email) {
        <mat-list-item class="email">
            <mat-icon matListItemIcon svgIcon="email"></mat-icon>
            <div matListItemTitle>{{config.email}}</div>
        </mat-list-item>
        }
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
