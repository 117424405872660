import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import moment from 'moment';
import { DailyMenuService } from '../../service/firebase/daily-menu.service';
import { Config, ConfigService } from '../../service/firebase/config.service';
import { DailyMenu } from '../../models/DailyMenu';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'dsm-menu-page',
    standalone: true,
    imports: [DecimalPipe],
    templateUrl: './menu.page.html',
    styleUrls: ['./menu.page.scss']
})
export class MenuPage implements OnInit {
    private weekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    menu?: DailyMenu;
    today: string;
    menuUpdated: boolean = false;
    menuAvailable: boolean = false;
    config?: Config;

    constructor(private menuService: DailyMenuService, private configService: ConfigService,
        private firestore: AngularFirestore) {
        this.today = new Date().toLocaleDateString('es-ES');
        this.configService.get().subscribe(config => {
            this.config = config;
            const weekDay = this.weekDays[moment().weekday()];
            this.menuAvailable = !!config?.menu.weekDaysEnabled.find(wd => wd === weekDay);
        });
    }

    ngOnInit(): void {
        this.menuService.get().subscribe(menu => {
            this.menu = menu;
            const isToday = moment().format("YYYY-MM-DD");
            const timestamp = menu.date as unknown as Timestamp;
            const menuDate = moment.unix(timestamp.seconds).format("YYYY-MM-DD");
            this.menuUpdated = moment(menuDate).isSame(isToday);
        });
    }
}
