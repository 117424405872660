<h2 mat-dialog-title>{{product.description}}</h2>
<mat-dialog-content>
    <p>{{product.obs}}</p>
    <div class="allergens">
        @if(!product.allergens || product.allergens.length === 0) {
            No contine alérgenos
        } @else {
            <mat-list-item><strong>Alergenos</strong></mat-list-item>
            <mat-list>
                @for(allergenId of product.allergens; track allergenId) {
                <mat-list-item>
                    <img src="/assets/icons/allergens/{{getAllergenIcon(allergenId)}}.png" class="allergen-icon"
                        alt="Imagen del alérgeno {{getAllergenDesc(allergenId)}}" />
                    {{getAllergenDesc(allergenId)}}
                </mat-list-item>
                }
            </mat-list>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>