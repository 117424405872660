import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseFirebaseService } from './base.service';
import { Allergen } from '../../models/Allergen';

@Injectable({
    providedIn: 'root'
})
export class AllergensService extends BaseFirebaseService<Allergen> {

    getAll(): Observable<Allergen[]> {
        return of(ALLERGENS);
        // return this.getFromCollection<Allergen>(this.collection);
    }
}

const ALLERGENS: Allergen[] = [
    { id: 'altramuces', description: 'Altramuces', icon: 'altramuces' },
    { id: 'apio', description: 'Apio', icon: 'apio' },
    { id: 'cacahuete', description: 'Cacahuete', icon: 'cacahuete' },
    { id: 'crustaceos', description: 'Crustaceos y derivados', icon: 'crustaceos' },
    { id: 'sesamo', description: 'Granos de sésamo', icon: 'sesamo' },
    { id: 'gluten', description: 'Gluten', icon: 'gluten' },
    { id: 'cascara', description: 'Frutos de cáscara', icon: 'cascara' },
    { id: 'huevo', description: 'Huevo', icon: 'huevo' },
    { id: 'lacteos', description: 'Lacteos y derivados', icon: 'lacteos' },
    { id: 'moluscos', description: 'Moluscos y derivados', icon: 'moluscos' },
    { id: 'mostaza', description: 'Mostaza', icon: 'mostaza' },
    { id: 'pescado', description: 'Pescado', icon: 'pescado' },
    { id: 'soja', description: 'Soja', icon: 'soja' },
    { id: 'sulfitos', description: 'Sulfitos y dioxido de azufre', icon: 'sulfitos' }
]
