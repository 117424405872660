import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar'

import { CartasService } from '../../../service/firebase/cartas.service';
import { DeviceService } from '../../../service/device.service';
import { AllergensService } from '../../../service/firebase/allergens.service';
import { GetTextDialog, TextDialogData } from '../../../dialogs/get-text/get-text.dialog';
import { ConfirmDialog, ConfirmDialogData } from '../../../dialogs/confirm/confirm.dialog';
import { AdminProductDialog } from '../../../dialogs/admin-product/admin-product.dialog';
import { Allergen } from '../../../models/Allergen';
import { Carta } from '../../../models/Carta';
import { Section } from '../../../models/Section';
import { Product } from '../../../models/Product';
import { DecimalPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';


@Component({
  selector: 'dsm-admin-carta',
  standalone: true,
  imports: [DecimalPipe, MatToolbarModule, MatIconModule, MatButtonModule, MatNativeDateModule],
  templateUrl: './admin-carta.page.html',
  styleUrls: ['./admin-carta.page.scss']
})
export class AdminCartaPage implements OnInit {
  displayedColumns: string[] = ['order-actions', 'order', 'title', 'numProducts', 'actions'];
  handset: boolean = false;
  saving: boolean = false;
  allergens: Allergen[] = [];
  carta!: Carta;
  sections!: Section[];

  constructor(
    private dialog: MatDialog,
    private cartasService: CartasService,
    private deviceService: DeviceService,
    private allergenService: AllergensService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.allergenService.getAll().subscribe(allergens => this.allergens = allergens);
    this.route.params.subscribe(params => {
      const cartaId = params['id'];
      if (cartaId) {
        this.cartasService.getById(cartaId).subscribe(carta => {
          this.carta = carta;
          this.sections = carta.sections;
        })
      }
      else {
      }
    })
    this.deviceService.isHandset$.subscribe(handset => {
      this.handset = handset;
    });
  }

  backToCartas() {
    this.router.navigate(['/admin/cartas']);
  }

  getAllergenIcon(allergenId: string): string {
    return this.allergens.find(a => a.id === allergenId)?.icon || 'not-found';
  }

  getAllergenDesc(allergenId: string): string {
    return this.allergens.find(a => a.id === allergenId)?.description || 'not-found';
  }

  newSection() {
    const data: TextDialogData = new TextDialogData('Título del apartado', '');
    const dialogRef = this.dialog.open(GetTextDialog, {
      width: this.handset ? '95%' : '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe((data: TextDialogData) => {
      if (data) {
        if (!this.carta.sections) {
          this.carta.sections = [];
        }
        const section: Section = {
          id: this.cartasService.getNewId(),
          title: data.text,
          order: this.carta.sections.length + 1,
          products: []
        };
        this.carta.sections.push(section);
        this.cartasService.save(this.carta!);
      }
    });
  }

  showSection(section: Section) {
    this.router.navigate([`${section.id}`], { relativeTo: this.route });
  }

  editSection(section: Section) {
    const data: TextDialogData = new TextDialogData('Título del apartado', section.title);
    const dialogRef = this.dialog.open(GetTextDialog, {
      width: this.handset ? '90%' : '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        section.title = data.text;
        this.cartasService.save(this.carta!);
      }
    });
  }

  deleteSection(section: Section) {
    const message: string = `¿Estás seguro/a de eliminar el apartado "${section.title}". Se eliminarán todos los productos asociados?`;
    const data: ConfirmDialogData = new ConfirmDialogData(message);
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: this.handset ? '90%' : '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const idx = this.carta.sections.findIndex(s => s === section);
        if (-1 !== idx) {
          this.carta.sections.splice(idx);
          this.cartasService.save(this.carta);
        }
      }
    });
  }

  newProduct(section: Section) {
    const dialogRef = this.dialog.open(AdminProductDialog, { width: this.handset ? '95%' : '700%', });
    dialogRef.afterClosed().subscribe((product: Product) => {
      if (product) {
        product.id = this.cartasService.getNewId();
        section.products.push(product);
        this.cartasService.save(this.carta);
      }
    });
  }

  editProduct(section: Section, product: Product) {
    const dialogRef = this.dialog.open(AdminProductDialog, {
      width: this.handset ? '95%' : '70%',
      data: product
    });

    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        const idx = section.products.findIndex(p => p.id === product.id);
        if (-1 !== idx) {
          section.products[idx] = product;
          this.cartasService.save(this.carta);
        }
      }
    });
  }

  deleteProduct(section: Section, product: Product) {
    const message: string = `¿Estás seguro/a de eliminar el producto "${product.description}"?`;
    const data: ConfirmDialogData = new ConfirmDialogData(message);
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: this.handset ? '95%' : '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const idx = section.products.findIndex(p => p.id === product.id) || -1;
        if (-1 !== idx) {
          section.products.splice(idx);
          this.cartasService.save(this.carta);
        }
      }
    });
  }

  getAllowOrder(product: Product) {
    return product.allowOrder ? 'Si' : 'No';
  }
}
