import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Carta } from '../../models/Carta';

@Component({
  selector: 'dsm-cartas',
  standalone: true,
  templateUrl: './cartas.component.html',
  styleUrls: ['./cartas.component.scss']
})
export class CartasComponent {

  @Input() cartas: Carta[] = [];

  constructor(private readonly router: Router) {
  }

  goCarta(carta: Carta) {
    this.router.navigate(['/carta'], { fragment: carta.id });
  }
}
