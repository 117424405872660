import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { UserDsm } from '../../models/UserDsm';
import { DeviceService } from '../../service/device.service';
import { AuthService } from '../../service/auth.service';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'dsm-top-toolbar',
    standalone: true,
    imports: [AsyncPipe, MatIconModule, MatMenuModule, MatButtonModule],
    templateUrl: './top-toolbar.component.html',
    styleUrls: ['./top-toolbar.component.scss']
})
export class TopToolbarComponent {

    @Input() title!: string;
    @Output() login: EventEmitter<void> = new EventEmitter<void>();
    @Output() logout: EventEmitter<void> = new EventEmitter<void>();

    constructor(private deviceService: DeviceService, private auth: AuthService) { }

    get isHandset$(): Observable<boolean> {
        return this.deviceService.isHandset$;
    }

    get user(): UserDsm {
        return this.auth.userData;
    }

    onLogin() {
        this.login.emit();
    }

    onLogout() {
        this.logout.emit();
    }
}
