import { Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { HomePage } from './pages/home/home.page';
import { CartasPage } from './pages/cartas/cartas.page';
import { CartaPage } from './pages/carta/carta.page';
import { MenuPage } from './pages/menu/menu.page';
import { ReadQrPage } from './pages/readqr/readqr.page';
import { AdminCartaPage } from './pages/admin/admin-carta/admin-carta.page';
import { AdminMenuPage } from './pages/admin/admin-menu/admin-menu.page';
import { AdminCartasPage } from './pages/admin/admin-cartas/admin-cartas.page';
import { AdminSettingsPage } from './pages/admin/admin-settings/admin-settings.page';
import { MapPage } from './pages/map/map.page';

export const routes: Routes = [
    { path: '', component: HomePage },
    {
        path: 'admin',
        canActivate: [AdminGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'menu-diario' },
            { path: 'menu-diario', component: AdminMenuPage, canActivate: [AdminGuard] },
            { path: 'cartas', component: AdminCartasPage, canActivate: [AdminGuard] },
            { path: 'cartas/:id', component: AdminCartaPage, canActivate: [AdminGuard] },
            { path: 'ajustes', component: AdminSettingsPage, canActivate: [AdminGuard] },
        ]
    },
    { path: 'cartas', component: CartasPage },
    { path: 'carta', component: CartaPage },
    { path: 'mapa', component: MapPage },
    { path: 'menu-diario', component: MenuPage },
    { path: 'leerqr', component: ReadQrPage },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
