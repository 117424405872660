@if(cartas) {
    @for(carta of cartas; track carta.id) {
        <div class="card" (click)="goCarta(carta)">
            <div class="title">
                <span>{{carta.title}}</span>
            </div>
            <span class="obs">{{carta.description}}</span>
        </div>
    }
}
