import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { CartasService } from '../../service/firebase/cartas.service';
import { AllergensService } from '../../service/firebase/allergens.service';
import { DeviceService } from '../../service/device.service';

import { ProductDialog } from '../../dialogs/product/product.dialog';

import { Carta } from '../../models/Carta';
import { Allergen } from '../../models/Allergen';
import { Section } from '../../models/Section';
import { Product } from '../../models/Product';
import { MatIconModule } from '@angular/material/icon';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'dsm-carta-page',
    standalone: true,
    imports: [MatIconModule, DecimalPipe],
    templateUrl: './carta.page.html',
    styleUrls: ['./carta.page.scss']
})
export class CartaPage implements OnInit {
    allergens: Allergen[] = [];
    carta?: Carta;
    isHandset!: boolean;
    sectionExpanded?: Section = undefined;

    constructor(
        private deviceService: DeviceService,
        private cartasService: CartasService,
        private allergenService: AllergensService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
    ) {
        this.deviceService.isHandset$.subscribe(isHandset => this.isHandset = isHandset);
    }

    ngOnInit(): void {
        this.allergenService.getAll().subscribe(allergens => this.allergens = allergens);
        this.route.fragment.subscribe(f => {
            this.cartasService.getById(f).subscribe(carta => {
                this.carta = carta;
                if (1 === carta.sections.length) {
                    this.sectionExpanded = carta.sections[0];
                }
            });
        });
    }

    showProdDetail(prod: Product) {
        const dialogRef = this.dialog.open(ProductDialog, {
            width: this.isHandset ? '95%' : '50%',
            data: prod
        });
    }

    isSectionExpanded(section: Section) {
        return section === this.sectionExpanded;
    }

    toggleSection(section: Section) {
        if (this.isHandset) {
            this.sectionExpanded = (section === this.sectionExpanded) ? undefined : section;
        }
    }

    getIconSection(section: Section) {
        if (this.sectionExpanded === section) {
            return 'expand_less';
        }

        return 'expand_more';
    }
}
