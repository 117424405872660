<h2 mat-dialog-title>Carta</h2>
<mat-dialog-content>
    <form [formGroup]="form" class="form">
        <mat-form-field>
            <input matInput placeholder="Título (este aparecerá como opción de menú al usuario)" type="text" required
                formControlName="title" />
            @if(f['title'].hasError('required')) {
            <mat-error >Es obligatorio introducir un título</mat-error>
            }
        </mat-form-field>
        <span class="gap"></span>
        <mat-form-field>
            <input matInput placeholder="Detalle del contenido de la carta (aparecerá en las cartas debajo del título)"
                type="text" required formControlName="description" />
                @if(f['description'].hasError('required')) {
                    <mat-error>Es obligatorio introducir un descripción de la carta</mat-error>
                }
        </mat-form-field>
        <span class="gap"></span>
        <mat-form-field>
            <textarea matInput
                placeholder="Observaciones de la carta (aparecerá al final de la carta en letra mas pequeña)"
                type="text" formControlName="obs" rows="4"></textarea>
        </mat-form-field>
        <span class="gap"></span>
        <mat-checkbox formControlName="isEnabled">Mostrar/Ocultar la carta a los usuarios</mat-checkbox>
        @if(form.value.isEnabled) {
        <span class="gap"></span>
        <div>
            Días en los que la carta está habilitada
            <mat-button-toggle-group name="weekDays" class="week-days" multiple [(value)]="carta.weekDaysEnabled">
                <mat-button-toggle value="L">L</mat-button-toggle>
                <mat-button-toggle value="M">M</mat-button-toggle>
                <mat-button-toggle value="X">X</mat-button-toggle>
                <mat-button-toggle value="J">J</mat-button-toggle>
                <mat-button-toggle value="V">V</mat-button-toggle>
                <mat-button-toggle value="S">S</mat-button-toggle>
                <mat-button-toggle value="D">D</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        }
        <span class="gap"></span>
        <mat-form-field>
            <textarea matInput placeholder="Notas internas de la carta (esto no lo verán los usarios)" type="text"
                formControlName="internalNote" rows="2"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancelar</button>
    <span class="gap"></span>
    <button mat-button color="primary" (click)="onSave()" [disabled]="!allowSave">
        <span>@if(isNew) { Añadir } @else { Actualizar }</span>
    </button>
</mat-dialog-actions>