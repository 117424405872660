// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    baseUrl: '',
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyCDBOSnkYN84W7OK7hanbYbfHpfeeVOBL8',
        // authDomain: 'la-dehesa-de-partenon.firebaseapp.com',
        authDomain: "dev.dehesa-partenon.es",
        databaseURL: 'https://la-dehesa-de-partenon.firebaseio.com',
        projectId: 'la-dehesa-de-partenon',
        storageBucket: 'la-dehesa-de-partenon.appspot.com',
        messagingSenderId: '880389807950',
        appId: '1:880389807950:web:9d6d3a9fa3c500e487481f'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
