<mat-toolbar>Menú diario</mat-toolbar>
<div class="main-container page-margin-1">
    @if(menuForm) {
    <form class="container" [formGroup]="menuForm" (ngSubmit)="onSubmit()">
        <div class="section">
            <mat-form-field>
                <mat-label>Fecha del menú</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Precio</mat-label>
                <input matInput type="number" formControlName="price">
            </mat-form-field>
        </div>
        <div class="section" formArrayName="firsts">
            <div class="section-title">
                <span class="title">Primeros platos ({{firsts.length}})</span>
                <span class="spacer"></span>
                @if(firsts.length < config!.menu.maxFirstPlates) {
                <mat-icon svgIcon="add" color="primary" (click)="addFirstPlate()"></mat-icon>
                }
            </div>
            <div class="section-row">
                @for(plate of firsts.controls; track i; let i = $index) {
                <div class="plato">
                    <mat-form-field class="full-row">
                        <input matInput placeholder="Descripción del primero" type="text" required
                            [formControlName]="i" />
                        @if(plate.hasError('required')) {
                        <mat-error>Ha de indicar un nombre para el primer plato</mat-error>
                        }
                    </mat-form-field>
                    @if(firsts.length > 1) {
                    <mat-icon svgIcon="remove" color="warn" (click)="removeFirstPlate(i)"></mat-icon>
                    }
                </div>
                }
            </div>
        </div>
        <div class="section" formArrayName="seconds">
            <div class="section-title">
                <span class="title">Segundos platos ({{seconds.length}})</span>
                <span class="spacer"></span>
                @if(seconds.length < config!.menu.maxSecondPlates) {
                <mat-icon svgIcon="add" color="primary" (click)="addSecondPlate()"></mat-icon>
                }
            </div>
            <div class="section-row">
                @for(plate of seconds.controls; track i; let i = $index) {
                <div class="plato">
                    <mat-form-field class="full-row">
                        <input matInput placeholder="Descripción del segundo" type="text" required
                            [formControlName]="i" />
                            @if(plate.hasError('required')) {
                                <mat-error>Ha de indicar un nombre para el segundo plato</mat-error>
                            }
                    </mat-form-field>
                    @if(seconds.length > 1) {
                    <mat-icon svgIcon="remove" color="warn" (click)="removeSecondPlate(i)"></mat-icon>
                    }
                </div>
                }
            </div>
        </div>
        <div class="section" formArrayName="desserts">
            <div class="section-title">
                <span class="title">Postres ({{seconds.length}})</span>
                <span class="spacer"></span>
                @if(desserts.length < config!.menu.maxDessertPlates) {
                <mat-icon svgIcon="add"color="primary" (click)="addDessertPlate()"></mat-icon>
                }
            </div>
            <div class="section-row">
                @for(plate of desserts.controls; track i; let i = $index) {
                <div class="plato">
                    <mat-form-field class="full-row">
                        <input matInput placeholder="Descripción del postre" type="text" required
                            [formControlName]="i" />
                        @if(plate.hasError('required')) {
                        <mat-error>Ha de indicar un nombre para el postre</mat-error>
                        }
                    </mat-form-field>
                    @if(desserts.length > 1) {
                    <mat-icon svgIcon="remove" color="warn" (click)="removeDessertPlate(i)"></mat-icon>
                    }
                </div>
                }
            </div>
        </div>
        <div class="section">
            <mat-form-field class="full-row">
                <textarea matInput placeholder="Nota a pie de menú" type="text" rows="2"
                    formControlName="obs"></textarea>
            </mat-form-field>
        </div>
        <div class="actions">
            <button mat-button (click)="discardChanges()">Descartar cambios</button>
            <button mat-raised-button color="primary" class="submit" [class.spinner]="saving"
                [disabled]="saving || badInputFields">Guardar</button>
        </div>
    </form>
    }
</div>