import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdminNavbarComponent } from '../admin-navbar/admin-navbar.component';
import { AuthService } from '../../service/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'dsm-main-navbar',
    standalone: true,
    imports: [MatIconModule, RouterModule],
    templateUrl: './main-navbar.component.html',
    styleUrls: ['./main-navbar.component.scss']
})
export class MainNavbarComponent {
    @Input() menuEnabled: boolean = true;

    constructor(private readonly bottomSheet: MatBottomSheet, public readonly auth: AuthService) {
    }

    isAdmin() {
        return this.auth.isAdmin();
    }

    showAdminMenu() {
        this.bottomSheet.open(AdminNavbarComponent);
    }
}
