import { Component, OnInit } from '@angular/core';
import { CartasService } from '../../service/firebase/cartas.service';
import { Carta } from '../../models/Carta';
import { CartasComponent } from '../../components/cartas/cartas.component';

@Component({
  selector: 'dsm-cartas-page',
  standalone: true,
  imports: [CartasComponent],
  templateUrl: './cartas.page.html',
  styleUrls: ['./cartas.page.scss']
})
export class CartasPage implements OnInit {
  cartas!: Carta[];

  constructor(private cartasService: CartasService) { }

  ngOnInit(): void {
    this.cartasService.getActives().subscribe(cartas => this.cartas = cartas);
  }
}
