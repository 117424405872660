import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CartasService } from '../../service/firebase/cartas.service';
import { DeviceService } from '../../service/device.service';
import { DailyMenuService } from '../../service/firebase/daily-menu.service';
import { Config, ConfigService } from '../../service/firebase/config.service';

import { Carta } from '../../models/Carta';
import { DailyMenu } from '../../models/DailyMenu';
import { LocalInfoDialog } from '../../dialogs/local-info/local-info.dialog';
import { CartasComponent } from '../../components/cartas/cartas.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'dsm-home-page',
  standalone: true,
  imports: [DecimalPipe, CartasComponent],
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

  cartas: Carta[] = [];
  menu?: DailyMenu;
  config?: Config;
  isHandset!: boolean;

  constructor(
    private deviceService: DeviceService,
    private router: Router,
    private dialog: MatDialog,
    private cartasService: CartasService,
    private menuService: DailyMenuService,
    private configService: ConfigService
  ) {
    this.deviceService.isHandset$.subscribe(isHandset => this.isHandset = isHandset);
  }

  ngOnInit(): void {
    this.cartasService.getActives().subscribe(cartas => {
      this.cartas = cartas;
    });
    this.configService.get().subscribe(config => {
      this.config = config;
      if (config?.menu?.enabled)
        this.menuService.get().subscribe(menu => {
          this.menu = menu;
        });
    })
  }

  goDailyMenu() {
    this.router.navigate(['/menu-diario']);
  }

  goCarta(carta: Carta) {
    this.router.navigate(['/carta'], { fragment: carta.id });
  }

  showInfoLocal() {
    const dialogRef = this.dialog.open(LocalInfoDialog, {
      width: this.isHandset ? '95%' : '50%',
      data: this.config
    });
  }
}
