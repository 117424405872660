import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseFirebaseService } from './base.service';
import { DailyMenu } from '../../models/DailyMenu';

@Injectable({ providedIn: 'root' })
export class DailyMenuService extends BaseFirebaseService<DailyMenu> {

    private collection: string = 'daily-menu';

    get(): Observable<DailyMenu> {
        return this.getFromCollection<DailyMenu>(this.collection).pipe(
            map(dm => dm[0] || new DailyMenu(this.firestore.createId(), new Date(), [], [], [], 0, ''))
        );
    }

    async save(id: string, menu: DailyMenu) {
        await this.firestore.collection(this.collection).doc(id).set(menu);
    }
}
