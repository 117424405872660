<dsm-top-toolbar [title]="title" (login)="auth.googleSignIn()" (logout)="auth.signOut()"></dsm-top-toolbar>
@if(noInternet) {
<div class="no-internet">Sin conexión. El contenido que ve puede estar obsoleto</div>
}
<mat-drawer-container class="main-container">
    <mat-drawer #drawer mode="side" [opened]="!(isHandset$ | async)" class="menu">
        <nav aria-label="Main menu">
            <a mat-button routerLink="" routerLinkActive="active">
                <mat-icon svgIcon="home"></mat-icon><span>Inicio</span>
            </a>
            @if(showMenu) {
            <a mat-button routerLink="/menu-diario" routerLinkActive="active">
                <mat-icon svgIcon="menu"></mat-icon><span>Menú diario</span>
            </a>
            }
            <a mat-button routerLink="/cartas" routerLinkActive="active">
                <mat-icon svgIcon="cartas"></mat-icon><span>Cartas</span>
            </a>
            <a mat-button routerLink="/mapa" routerLinkActive="active">
                <mat-icon svgIcon="place"></mat-icon><span>Localización</span>
            </a>
            @if(isAdmin()) {
            <nav aria-label="Admin menu">
                <span class="top-submenu" disabled>
                    <mat-icon svgIcon="admin"></mat-icon>
                    <span>Administración</span>
                </span>
                <a mat-button routerLink="/admin/menu-diario" routerLinkActive="active">
                    <mat-icon svgIcon="menu"></mat-icon><span>Menú diario</span>
                </a>
                <a mat-button routerLink="/admin/cartas" routerLinkActive="active">
                    <mat-icon svgIcon="cartas"></mat-icon><span>Cartas</span>
                </a>
                <a mat-button routerLink="/admin/ajustes" routerLinkActive="active">
                    <mat-icon svgIcon="settings"></mat-icon><span>Ajustes</span>
                </a>
            </nav>
            }
        </nav>
    </mat-drawer>
    <mat-drawer-content>
        <div [@routeAnimations]="prepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
        @if(isHandset$ | async) {
        <nav class="bottom-nav-bar" aria-label="Burger menu for small devices">
            <dsm-main-navbar [menuEnabled]="showMenu"></dsm-main-navbar>
        </nav>
        }
    </mat-drawer-content>
</mat-drawer-container>