import { Injectable, inject } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from './firebase/user.service';
import { Auth, getAuth } from '@angular/fire/auth';
import { signInWithPopup, GoogleAuthProvider, UserCredential, User } from "firebase/auth";

@Injectable({ providedIn: 'root' })
export class AuthService {
    private auth: Auth;;
    userData: any;

    constructor(private readonly userService: UserService, private readonly router: Router) {
        this.auth = getAuth();
        this.auth.useDeviceLanguage();
        this.auth.onAuthStateChanged(user => {
            /* Saving user data in localstorage when 
            logged in and setting up null when logged out */
            if (user) {
                this.userData = user;
                this.updateUserData(user);
            }
        });
    }

    async googleSignIn() {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        await this.authLogin(provider);
        this.router.navigate(['dashboard']);
    }

    // Auth logic to run auth providers
    async authLogin(provider: any) {
        signInWithPopup(this.auth, provider).then((result: UserCredential) => {
            this.router.navigate(['/']);
        }).catch((error) => {
            window.alert(error);
        });
    }

    // Sign out 
    async signOut() {
        this.auth.signOut().then(() => {
            this.updateUserData(null);
            this.router.navigate(['/']);
        });
    }

    isAdmin(): boolean {
        return this.userData?.role === 'admin';
    }

    /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    private async updateUserData(user: User | null) {
        if (!!user) {
            const userSnap = await this.userService.getUserByUid(user.uid);
            if( !!userSnap ) {
                this.userData = {
                    uid: user.uid,
                    email: user.email ?? '',
                    displayName: user.displayName ?? '',
                    photoURL: user.photoURL ?? '',
                    emailVerified: user.emailVerified,
                    role: userSnap.role
                };
            }
        }
        else {
            this.userData = null;
        }
    }
}
