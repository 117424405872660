import { Component } from '@angular/core';
import { AsyncPipe, registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es';
import { RouterModule, RouterOutlet } from '@angular/router';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable, fromEvent } from 'rxjs';
import moment from 'moment';

import { slideInAnimation } from './animations/app.animations';
import { AuthService } from './service/auth.service';
import { DeviceService } from './service/device.service';
import { PwaService } from './service/pwa.service';
import { ConfigService } from './service/firebase/config.service';
import { TopToolbarComponent } from './components/top-toolbar/top-toolbar.component';
import { MainNavbarComponent } from './components/main-navbar/main-navbar.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'dsm-app',
  standalone: true,
  imports: [RouterOutlet, RouterModule, AsyncPipe, MatIconModule, MatButtonModule, MatSidenavModule, TopToolbarComponent, MainNavbarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [slideInAnimation]
})
export class AppComponent {
    title = 'La Dehesa de Partenón';
    showMenu: boolean = false;
    noInternet: boolean = false;

    constructor(
        public pwaService: PwaService,
        private deviceService: DeviceService,
        public auth: AuthService,
        private configService: ConfigService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        fromEvent(window, 'offline').subscribe(e => { this.noInternet = true });
        fromEvent(window, 'online').subscribe(e => { this.noInternet = false });

        registerLocaleData(localeEs, 'es');
        moment.locale('es');
        this.registryIcons();
        this.configService.get().subscribe(config => {
            this.title = config?.name ?? 'Local desconocido';
            this.showMenu = config?.menu?.enabled || false;
        });
    }

    get isHandset$(): Observable<boolean> {
        return this.deviceService.isHandset$;
    }

    isAdmin() {
        return this.auth.isAdmin();
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }

    private registryIcons() {
        const basePath = 'assets/icons/material';
        const icons = ['account_circle', 'add', 'admin', 'back', 'camera', 'cartas', 'delete', 'edit', 'email', 'exit', 'expand_less', 'expand_more', 'info', 'home', 'menu', 'partenon', 'phone', 'place', 'qr_code', 'remove', 'schedule', 'settings'];
        icons.forEach(icon => {
            this.iconRegistry.addSvgIcon(icon,
                this.sanitizer.bypassSecurityTrustResourceUrl(`${basePath}/${icon}-24px.svg`));
        });
    }
}
