import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatToolbarModule } from '@angular/material/toolbar';

import { DailyMenuService } from '../../../service/firebase/daily-menu.service';
import { Config, ConfigService } from '../../../service/firebase/config.service';

import { DailyMenu } from '../../../models/DailyMenu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'dsm-admin-menu',
    standalone: true,
    imports: [MatToolbarModule, MatIconModule, MatButtonModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, MatInputModule, MatDatepickerModule],
    templateUrl: './admin-menu.page.html',
    styleUrls: ['./admin-menu.page.scss']
})
export class AdminMenuPage implements OnInit {

    config?: Config;
    menuForm!: UntypedFormGroup;
    menu!: DailyMenu;
    saving: boolean = false;

    constructor(private fb: UntypedFormBuilder, private menuService: DailyMenuService, private configService: ConfigService) {
        this.configService.get().subscribe(config => this.config = config);
    }

    ngOnInit(): void {
        this.loadMenu(true);
    }

    get mfc() {
        return this.menuForm.controls;
    }

    get firsts(): UntypedFormArray {
        return this.menuForm.get('firsts') as UntypedFormArray;
    }

    addFirstPlate() {
        this.firsts.push(new UntypedFormControl('', Validators.required));
    }

    removeFirstPlate(index: number) {
        this.firsts.removeAt(index);
    }

    get seconds(): UntypedFormArray {
        return this.menuForm.get('seconds') as UntypedFormArray;
    }

    addSecondPlate() {
        this.seconds.push(new UntypedFormControl('', Validators.required));
    }

    removeSecondPlate(index: number) {
        this.seconds.removeAt(index);
    }

    get desserts(): UntypedFormArray {
        return this.menuForm.get('desserts') as UntypedFormArray;
    }

    addDessertPlate() {
        this.desserts.push(new UntypedFormControl('', Validators.required));
    }

    removeDessertPlate(index: number) {
        this.desserts.removeAt(index);
    }

    async onSubmit() {
        this.saving = true;
        this.menuForm.value.date = new Date();
        await this.menuService.save(this.menu.id, this.menuForm.value);
        this.saving = false;
    }

    get badInputFields(): boolean {
        return !this.menuForm.valid;
    }

    discardChanges() {
        this.loadMenu(true);
        return false;
    }

    private createForm() {
        this.menuForm = this.fb.group({
            date: [{ value: new Date(), disabled: true }, Validators.required],
            price: [this.menu.price, Validators.required],
            obs: [this.menu.obs],
            firsts: this.fb.array(this.menu.firsts, Validators.required),
            seconds: this.fb.array(this.menu.seconds, Validators.required),
            desserts: this.fb.array(this.menu.desserts, Validators.required)
        });
    }

    private loadMenu(createForm: boolean) {
        this.menuService.get().subscribe(menu => {
            this.menu = menu;
            if (createForm) {
                this.createForm();
            }
        });
    }
}
