<mat-toolbar>Cartas</mat-toolbar>
<div class="main-container page-margin-1">
    <div class="table-container">
        <section class="table-toolbar">
            <button mat-button (click)="newCarta()" class="table-action" color="accent">
                <mat-icon svgIcon="add" class="icon md-20"></mat-icon>
                <span>crear nueva carta</span>
            </button>
        </section>
        <table mat-table [dataSource]="cartas" class="table mat-elevation-z2">
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Título</th>
                <td mat-cell *matCellDef="let element">{{element.title}}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Descripción</th>
                <td mat-cell *matCellDef="let element">{{element.description}}</td>
            </ng-container>
            <ng-container matColumnDef="isEnabled">
                <th mat-header-cell *matHeaderCellDef>Habilitada?</th>
                <td mat-cell *matCellDef="let element">{{getEnabled(element.isEnabled)}} ({{getEnabledDays(element)}})
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="actions-column">
                    <a type="button" (click)="editCarta($event, element)">
                        <mat-icon svgIcon="edit" class="action-icon"></mat-icon>
                    </a>
                    <a type="button" (click)="deleteCarta($event, element)">
                        <mat-icon svgIcon="delete" class="action-icon"></mat-icon>
                    </a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showCarta(row)"></tr>
        </table>
    </div>
</div>