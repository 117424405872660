import { Component, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
// import { BrowserQRCodeReader } from '@zxing/library';

@Component({
  selector: 'dsm-readqr-page',
  templateUrl: './readqr.page.html',
  styleUrls: ['./readqr.page.scss']
})
export class ReadQrPage implements AfterViewInit {
  // private codeReader = new BrowserQRCodeReader();

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    if (!!(navigator?.mediaDevices?.getUserMedia)) {
      var video = document.querySelector("#video") as HTMLVideoElement;
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => video!.srcObject = stream).catch(this.handleError);
      // this.codeReader
      //   .decodeFromVideo('video', undefined)
      //   .then(result => console.log(result.getText()))
      //   .catch(err => console.error(err));
    } else {
      alert('Sorry, camera not available.');
    }
  }

  handleError(error: any) {
    console.log('Error: ', error);
  }

  onScanSuccess(data: string) {
    alert(data);
  }

  onScanError(err: any) {
    alert(err);
  }
}
