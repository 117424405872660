<mat-dialog-content>
  @if(data.message) {
  <span>{{ data.message }}</span>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button [mat-dialog-close]="true" color="accent">
    <span>@if(data.confirmBtn) { {{ data.confirmBtn }} } @else {Si, adelante!}</span>
  </button>
</mat-dialog-actions>