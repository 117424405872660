<h2 mat-dialog-title>Producto</h2>
<mat-dialog-content class="content">
    <form [formGroup]="form" class="form">
        <mat-form-field>
            <input matInput placeholder="Descripción" type="text" required formControlName="description" />
            @if(f['description'].hasError('required')) {
            <mat-error >Es obligatorio introducir un descripción del producto</mat-error>
            }
        </mat-form-field>
        <span class="gap"></span>
        <mat-form-field>
            <textarea matInput placeholder="Observaciones" type="text" formControlName="obs" rows="2"></textarea>
        </mat-form-field>
        <span class="gap"></span>
        <mat-form-field>
            <input matInput placeholder="Precio" type="number" required formControlName="price" />
            @if(f['price'].hasError('min')) {
            <mat-error>El precio del producto ha de ser mayor que 0</mat-error>
            }
        </mat-form-field>
        <span class="gap"></span>
        <mat-form-field>
            <mat-label>Alergenos</mat-label>
            <mat-select multiple class="allergen" formControlName="allergens">
                @for(allergen of allergens; track allergen.id) {
                <mat-option [value]="allergen.id">
                    {{allergen.description}}
                    <img src="/assets/icons/allergens/{{allergen.icon}}.png" class="allergen-icon"
                        alt="Imagen del alérgeno {{allergen.description}}" />
                </mat-option>
                }
            </mat-select>
            <mat-hint>Selecciona los alérgenos del plato, si los tuviera</mat-hint>
        </mat-form-field>
        <span class="gap"></span>
        <section>
            <mat-slide-toggle color="primary" formControlName="allowOrder">
                Permitir realizar pedidos de este producto
            </mat-slide-toggle>
        </section>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancelar</button>
    <button mat-button color="primary" (click)="onSave()" [disabled]="!allowSave">
        <span>@if(isNew) { Añadir } @else { Actualizar }</span>
    </button>
</mat-dialog-actions>