<mat-toolbar class="toolbar">
    <span>Ajustes</span>
    @if(changesPending) {
    <button mat-raised-button (click)="save()">Guardar</button>
    }
</mat-toolbar>
@if(!!config) {
<div class="main-container page-margin-1">
    <section class="section">
        <p class="title-section">
            <span>Configuración del menú diario</span>
            <mat-slide-toggle [(ngModel)]="config.menu.enabled"></mat-slide-toggle>
        </p>
        @if(config.menu.enabled) {
        <section class="subsection">
            <p>Dias del menú
                <mat-button-toggle-group name="weekDays" class="week-days" multiple
                    [(value)]="config.menu.weekDaysEnabled">
                    <mat-button-toggle value="L">L</mat-button-toggle>
                    <mat-button-toggle value="M">M</mat-button-toggle>
                    <mat-button-toggle value="X">X</mat-button-toggle>
                    <mat-button-toggle value="J">J</mat-button-toggle>
                    <mat-button-toggle value="V">V</mat-button-toggle>
                    <mat-button-toggle value="S">S</mat-button-toggle>
                    <mat-button-toggle value="D">D</mat-button-toggle>
                </mat-button-toggle-group>
            </p>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Desde (formato 24h)</mat-label>
                <input [(ngModel)]="config.menu.from" matInput placeholder="11:00">
                <mat-icon matSuffix svgIcon="schedule"></mat-icon>
                <mat-hint>Hora a la que comienza el servicio del menú diario</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Hasta (formato 24h)</mat-label>
                <input [(ngModel)]="config.menu.to" matInput placeholder="16:00">
                <mat-icon matSuffix svgIcon="schedule"></mat-icon>
                <mat-hint>Hora a la finaliza el servicio del menú diario</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Título entrantes</mat-label>
                <input [(ngModel)]="config.menu.starters" matInput placeholder="Entrantes">
                <mat-hint>Texto que se mostrará antes de los primeros platos</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Título segundos</mat-label>
                <input [(ngModel)]="config.menu.maindishes" matInput placeholder="Plato fuerte">
                <mat-hint>Texto que se mostrará antes de los segundos platos</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Título postres</mat-label>
                <input [(ngModel)]="config.menu.desserts" matInput placeholder="Postres">
                <mat-hint>Texto que se mostrará antes de los postres</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Mensaje para menú no actualizado</mat-label>
                <textarea [(ngModel)]="config.menu.noMenuMessage" matInput placeholder="Menú no actualizado"
                    rows="3"></textarea>
                <mat-hint>Este mensaje aparecerá cuando en el día no se haya actualizado el menú</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-form-field">
                <mat-label>Mensaje para menú no disponible</mat-label>
                <textarea [(ngModel)]="config.menu.noAvailableMessage" matInput placeholder="Menú no disponible"
                    rows="3"></textarea>
                <mat-hint>Este mensaje aparecerá cuando el día en curso no este seleccionado como disponible el menú
                </mat-hint>
            </mat-form-field>
        </section>
        }
    </section>
    <section class="section">
        <p class="title-section">Configuraciones varias</p>
        <p class="toggle-option">
            <span>Los precios tienen los impuestos incluidos</span>
            <mat-slide-toggle [(ngModel)]="config.taxesIncluded"></mat-slide-toggle>
        </p>
        <mat-hint>Cambiaran los literales de los precios, indicando si se incluyen inpuestos o no</mat-hint>
    </section>
</div>
}