import { Component, OnInit } from '@angular/core';
import { Config, ConfigService } from '../../../service/firebase/config.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'dsm-admin-settings',
  standalone: true,
  imports: [MatToolbarModule, MatSlideToggleModule, MatButtonModule, MatButtonToggleModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule, FormsModule],
  templateUrl: './admin-settings.page.html',
  styleUrls: ['./admin-settings.page.scss']
})
export class AdminSettingsPage implements OnInit {
  config?: Config;
  oldConfig!: Config;

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.get().subscribe(config => {
      this.config = config;
      this.oldConfig = JSON.parse(JSON.stringify(config));
    });
  }

  get changesPending(): boolean {
    return JSON.stringify(this.config) !== JSON.stringify(this.oldConfig);
  }

  save() {
    if (this.config) {
      this.configService.save(this.config);
    }
  }
}
