<nav class="main-navbar">
    <a routerLink="/" routerLinkActive="active">
        <mat-icon svgIcon="home"></mat-icon><span>Inicio</span>
    </a>
    @if(menuEnabled) {
    <a routerLink="/menu-diario" routerLinkActive="active">
        <mat-icon svgIcon="menu"></mat-icon><span>Menú</span>
    </a>
    }
    <a routerLink="/cartas" routerLinkActive="active">
        <mat-icon svgIcon="cartas"></mat-icon><span>Cartas</span>
    </a>
    <a routerLink="/mapa" routerLinkActive="active">
        <mat-icon svgIcon="place"></mat-icon><span>Localización</span>
    </a>
    @if(isAdmin()) {
    <a (click)="showAdminMenu()" routerLinkActive="active">
        <mat-icon svgIcon="admin"></mat-icon><span>Administración</span>
    </a>
    }
</nav>