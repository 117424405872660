import { Injectable } from "@angular/core";
import { UserDsm } from "../../models/UserDsm";
import { map, Observable } from "rxjs";
import { BaseFirebaseService } from "./base.service";
import { doc, Firestore, getDoc } from "@angular/fire/firestore";

@Injectable({ providedIn: 'root' })
export class UserService extends BaseFirebaseService<UserDsm> {
    private readonly collection: string = 'users';

    constructor(public fs: Firestore) {
        super();
     }

    getUserById(id: string): Observable<UserDsm | undefined> {
        return this.getFromCollection<UserDsm>(this.collection).pipe(
            map(user => user.filter(u => u.uid === id)[0])
        );
    }

    async getUserByUid(uid: string) {
        try {
            const userRef = doc(this.fs, 'users', uid);
            const userSnap = await getDoc(userRef);
            
            if( userSnap.exists() ) {
                return userSnap.data() as UserDsm;
            } else {
                return null;
            }
        } catch (error) {
            throw error;
        }
    }
}