import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { Carta } from '../../models/Carta';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'dsm-add-product',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonToggleModule],
  templateUrl: './carta.dialog.html',
  styleUrls: ['./carta.dialog.scss']
})
export class CartaDialog implements OnInit {

  form!: UntypedFormGroup;
  isNew: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public carta: Carta,
    private dialogRef: MatDialogRef<Carta>,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    if (!this.carta) {
      this.carta = { title: '', description: '', obs: '', sections: [], isEnabled: false, order: 1, weekDaysEnabled: [], internalNote: '' };
      this.isNew = true;
    }
    this.createFomrGroup();
  }

  get f() {
    return this.form.controls;
  }

  get allowSave(): boolean {
    return this.form.valid;
  }

  onSave() {
    this.dialogRef.close({ ...this.carta, ...this.form.value });
  }

  onCancel() {
    this.dialogRef.close();
  }

  private createFomrGroup() {
    this.form = this.formBuilder.group({
      id: [this.carta.id],
      title: [this.carta.title, Validators.required],
      description: [this.carta.description, Validators.required],
      obs: [this.carta.obs],
      isEnabled: [this.carta.isEnabled, Validators.required],
      internalNote: [this.carta.internalNote],
    });
  }
}
