import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';

import { AllergensService } from '../../service/firebase/allergens.service';

import { Product } from '../../models/Product';
import { Allergen } from '../../models/Allergen';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'dsm-add-product',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatListModule],
  templateUrl: './product.dialog.html',
  styleUrls: ['./product.dialog.scss']
})
export class ProductDialog implements OnInit {

  allergens!: Allergen[];
  allowOrder: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public product: Product,
    private dialogRef: MatDialogRef<Product>,
    private allergensService: AllergensService) { }

  ngOnInit(): void {
    this.allergensService.getAll().subscribe(allergens => this.allergens = allergens);
  }

  getAllergenDesc(allergenId: string) {
    return this.allergens.find(a => a.id === allergenId)?.description;
  }

  getAllergenIcon(allergenId: string) {
    return this.allergens.find(a => a.id === allergenId)?.icon;
  }

  onClose() {
    this.dialogRef.close();
  }
}
