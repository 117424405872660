<div class="container page-margin-3">
    <div class="logo" (click)="showInfoLocal()">
        <img src="/assets/icons/icon-384x384.png" />
    </div>
    @if(menu) {
    <div (click)="goDailyMenu()">
        @if(!!menu) {
        <div class="menu">
            <div class="menu-title">
                <span>menú</span>
                <span>diario</span>
            </div>
            <span class="price">{{menu!.price | number:'1.2-2':'es'}} €</span>
        </div>
        }
    </div>
    }
    <dsm-cartas [cartas]="cartas"></dsm-cartas>
</div>