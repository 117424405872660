import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { CartasService } from '../../../service/firebase/cartas.service';
import { DeviceService } from '../../../service/device.service';

import { CartaDialog } from '../../../dialogs/carta/carta.dialog';
import { ConfirmDialog, ConfirmDialogData } from '../../../dialogs/confirm/confirm.dialog';

import { Carta } from '../../../models/Carta';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'dsm-admin-cartas',
  standalone: true,
  imports: [MatIconModule, MatToolbarModule, MatTableModule, MatButtonModule],
  templateUrl: './admin-cartas.page.html',
  styleUrls: ['./admin-cartas.page.scss']
})
export class AdminCartasPage implements OnInit {
  displayedColumns: string[] = ['title', 'description', 'isEnabled', 'actions'];
  handset: boolean = false;
  saving: boolean = false;
  cartas!: Carta[];

  constructor(
    private dialog: MatDialog,
    private cartasService: CartasService,
    private deviceService: DeviceService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.cartasService.getAll().subscribe(cartas => this.cartas = cartas.sort((a, b) => (a.order > b.order) ? 1 : -1));
    this.deviceService.isHandset$.subscribe(handset => {
      this.handset = handset;
    });
  }

  newCarta() {
    const carta: Carta | undefined = undefined;
    const dialogRef = this.dialog.open(CartaDialog, {
      width: this.handset ? '95%' : '50%',
      data: carta
    });

    dialogRef.afterClosed().subscribe(carta => {
      if (carta) {
        this.cartasService.save(carta);
      }
    });
  }

  showCarta(carta: Carta) {
    this.router.navigate([`${carta.id}`], { relativeTo: this.route });
  }

  editCarta(event: Event, carta: Carta) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(CartaDialog, {
      width: this.handset ? '95%' : '50%',
      data: carta
    });

    dialogRef.afterClosed().subscribe(carta => {
      if (carta) {
        this.cartasService.save(carta);
      }
    });
  }

  deleteCarta(event: Event, carta: Carta) {
    event.stopPropagation();
    const message: string = `¿Estás seguro/a de eliminar la carta "${carta.title}"?`;
    const data: ConfirmDialogData = new ConfirmDialogData(message);
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: this.handset ? '95%' : '70%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cartasService.delete(carta);
      }
    });
  }

  getEnabled(enabled: boolean) {
    return enabled ? 'Si' : 'No';
  }

  getEnabledDays(carta: Carta) {
    return carta.weekDaysEnabled.toString();
  }
}
