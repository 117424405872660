@if(!!carta) {
<mat-toolbar class="title">
    <mat-icon svgIcon="back" class="back" (click)="backToCartas()"></mat-icon>
    Carta "{{ carta.title }}"
</mat-toolbar>
<div class="page-margin-1">
    <section class="table-container">
        @if('' !== carta!.description) {
        <p class="carta-desc">{{ carta!.description }}</p>
        }
        @if(carta.internalNote && '' !== carta!.internalNote) {
        <p class="carta-desc"><strong>Nota interna</strong>{{ carta!.internalNote }}</p>
        }
        <section class="table-toolbar">
            <button mat-button (click)="newSection()" class="table-action" color="accent">
                <mat-icon svgIcon="add" class="icon md-20"></mat-icon>
                <span>crear nuevo apartado</span>
            </button>
        </section>
        @for(section of carta.sections; track section.id) {
        <section class="section">
            <div class="section-title">
                <span>{{ section.title }}</span>
                <span class="actions">
                    <mat-icon svgIcon="edit" (click)="editSection(section)"></mat-icon>
                    <mat-icon svgIcon="delete" (click)="deleteSection(section)"></mat-icon>
                </span>
            </div>
            @for(product of section.products; track i; let i = $index) {
            <div class="prod-container">
                <div class="prod-actions">
                    <mat-icon svgIcon="edit" (click)="editProduct(section, product)"></mat-icon>
                    <mat-icon svgIcon="delete" (click)="deleteProduct(section, product)"></mat-icon>
                </div>
                <div class="prod">
                    <div class="prod-desc">
                        <span>{{ product.description }}</span>
                        <span class="prod-price">{{ product.price | number: '1.2-2':'es' }} €</span>
                    </div>
                    @if(product.obs && '' !== product.obs) {
                    <p class="prod-obs">{{ product.obs }}</p>
                    }
                    <p>Permite pedidos: <strong>{{ getAllowOrder(product) }}</strong></p>
                    <div class="prod-bottom">
                        <span class="allergens">
                            Alérgenos:
                            @for(allergen of product.allergens; track allergen) {
                            <span>
                                <img src="/assets/icons/allergens/{{getAllergenIcon(allergen)}}.png"
                                    class="allergen-icon" alt="Imagen del alérgeno {{getAllergenDesc(allergen)}}" />
                            </span>
                            } @empty {
                            <span><strong>No indicados</strong></span>
                            }
                        </span>
                    </div>
                </div>
            </div>
            }
            <section class="table-toolbar">
                <button mat-button (click)="newProduct(section)" class="table-action" color="accent">
                    <mat-icon svgIcon="add" class="icon md-20"></mat-icon>
                    <span>crear producto</span>
                </button>
            </section>
        </section>
        }
        @if('' !== carta.obs) {
        <p class="obs">{{carta.obs}}</p>
        }
    </section>
</div>
}