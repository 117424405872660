import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PwaService {
    constructor(private readonly swUpdate: SwUpdate) {
        this.swUpdate.versionUpdates
            .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
            .subscribe((evt) => {
                if (confirm('Hay una nueva versión de la App. ¿Quieres actualizar a la última versión?')) {
                    this.swUpdate.activateUpdate().then(() => {
                        window.location.reload();
                    });
                }
            });
    }
}
